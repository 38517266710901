import {lazy, useCallback} from 'react';
import {Routes, Route, Navigate,} from "react-router-dom";
import {version} from "./../package.json";

const HomeScreen = lazy(() => import("./Screens/HomeScreen"));
const HomeScreenOld = lazy(() => import("./Screens/HomeScreenOld"));
const TeachersScreen = lazy(() => import("./Screens/TeachersScreen"));
const MathematicsScreen = lazy(() => import("./Screens/MathematicsScreen"));
const InformaticsScreen = lazy(() => import("./Screens/InformaticsScreen"));
const UsefulLinksScreen = lazy(() => import("./Screens/UsefulLinksScreen"));
const MoScreen = lazy(() => import("./Screens/MoScreen"));
const ViewerDocumentScreen = lazy(() => import("./Screens/ViewerDocumentScreen"));
const FileTest = lazy(() => import("./Screens/FileTest"));

function App() {
    const getRoutes = useCallback(() => {
        return [
            {path: '/', element: <HomeScreenOld/>},
            {path: '/new', element: <HomeScreen/>},
            {path: '/teachers-page', element: <Builder><TeachersScreen/></Builder>},
            {path: '/mathematics-page', element: <Builder><MathematicsScreen/></Builder>},
            {path: '/informatics-page', element: <Builder><InformaticsScreen/></Builder>},
            {path: '/useful-links-page', element: <Builder><UsefulLinksScreen/></Builder>},
            {path: '/viewer-file-page', element: <ViewerDocumentScreen/>},
            {path: '/mo-page', element: <Builder><MoScreen/></Builder>},
            {path: '/file-test', element: <Builder><FileTest/></Builder>},
            {path: '*', element: <Navigate to={'/'}/>}
        ];
    }, [])
    return (
        <Routes>
            {getRoutes().map(
                ({path, element}, index) => <Route key={index} path={path} element={element}/>
            )}
        </Routes>
    )
}

export default App

function Builder({children}) {
    return (
        <>
            {children}
            <div className={`version-build`}>version build <span>{version}</span></div>
            <div className={`created`}>Created by <a href="https://flyadmin.ru" target={"_blank"}>Flyadmin</a></div>
        </>
    )
}
