import useBrowserTabChanger from "../hooks/BrowserTabChanger.jsx";
import {useEffect, useState} from "react";

let timerShow;
export default function LeftTheTab() {
    const [title, setTitle] = useState(``);
    const show = useBrowserTabChanger();
    useEffect(() => {
        if (document.title !== `🔥 Вы покинули вкладку :(`)
            setTitle(document.title)
    }, [document.title])
    useEffect(() => {
        if (!show) document.title = `🔥 Вы покинули вкладку :(`
        else document.title = title;
    }, [show])
}