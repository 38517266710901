import {useEffect, useState} from "react";

export default function useBrowserTabChanger(){
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        function onVisibilityChange(){
            if(document.visibilityState === `visible` && visible === false) setVisible(true)
            else if(visible === true) setVisible(false)
        }
        document.addEventListener('visibilitychange', onVisibilityChange, true)
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange, true)
        }
    }, [visible])
    return visible;
}