import React, {createContext, Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {BrowserRouter} from "react-router-dom"
import styled from "styled-components";
import LeftTheTab from "./components/LeftTheTab.jsx";
import {ToastContainer} from "react-toastify";

import("./assets/styles.css");
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Suspense fallback={<Loading/>}>
            <BrowserRouter>
                <App/>
                <LeftTheTab/>
                <ToastContainer />
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
)

function Loading() {
    return (
        <Loader>
            <LoaderContent>Загрузка...</LoaderContent>
        </Loader>
    )
}

const Loader = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(33, 150, 83, 0.7);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
const LoaderContent = styled.div`
  font-size: 24px;
  color: #FFFFFF;
`;
